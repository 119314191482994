const config = require("./custom-redirects-config");

exports.onPreRouteUpdate = ({ location }) => {
    if (config) {
        const path = location.pathname;
        config.default.options.routes.forEach(value => {
            if (value.path === path && value.newPath)
                window.location.replace(value.newPath);
            else if (value.path === path)
                window.location.replace(config.default.options.protocol + "://" + config.default.options.baseUrl + path);
        });
    }
};
// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-palvelut-js": () => import("./../../../src/pages/palvelut.js" /* webpackChunkName: "component---src-pages-palvelut-js" */),
  "component---src-pages-some-ja-nuoret-js": () => import("./../../../src/pages/some-ja-nuoret.js" /* webpackChunkName: "component---src-pages-some-ja-nuoret-js" */),
  "component---src-pages-tuotteet-js": () => import("./../../../src/pages/tuotteet.js" /* webpackChunkName: "component---src-pages-tuotteet-js" */),
  "component---src-pages-yhteystiedot-js": () => import("./../../../src/pages/yhteystiedot.js" /* webpackChunkName: "component---src-pages-yhteystiedot-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}


/*
 * If newPath key is specified, path value will redirect using newPath value.
 * https://example.com/path => https://example.com/newPath
 *
 * If path key is specified but newPath key is not, path value will redirect using baseUrl value.
 * https://example.com/path => https://baseurl.com/path
 *
 * { path: "/route" }                           => protocol +  baseUrl + path           => https://baseurl.com/route
 * { path: "/route", newPath: "/newRoute" }     => protocol + currentUrl + newPath      => https://currenturl.com/newRoute
 */

export default {
    resolve: "custom-redirects",
    options: {
        baseUrl: "wordpress.ebrand.fi",
        protocol: "https",
        routes: [
            {path: "/somejanuoret2019"},
            {path: "/somejanuoret2016"},
            {path: "/somejanuoret2015"},
            {path: "/somejanuoret2013"},
        ]
    }
};
